*, ::before, ::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  padding-bottom: 130px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container{
  width: 1230px;
  max-width: 100%;
  padding: 0 15px;
  display: block;
  margin: 0 auto;
}

/* Header */
.header{
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
}

.header .container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Header right */
.header-right{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.header-user,
.header-cart{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 0px 10px rgba(0,0,0,.2);
  margin-right: 10px;
}

.header-cart.active{
  background-color: #60b70b;
}

.header-user img,
.header-cart img{
  height: 25px;
}

/* Places */
.places{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 30px;
  margin-bottom: 30px;
}

/* Place */
.place {
  text-decoration: none;
}

.place div{
  height: 200px;
  width: 100%;
  background-size: cover;
  margin-bottom: 10px;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 0.3s ease-out 0s;
  transition: -webkit-transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s, -webkit-transform 0.3s ease-out 0s;
  border-radius: 4px;
}

.place:hover div{
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.place h3{
  font-size: 16px;
  color: #262626;
}

.place h4{
  margin-top: 5px;
  font-size: 14px;
  color: #757575;
}

/* Search */
.search {
  position: relative;
  width: 100%;
  margin: 10px 0 30px;
  border-bottom: 1px solid gray;
  height: 50px;
}

.search img{
  position: absolute;
  bottom: 10px;
  left: 0;
  height: 25px;
}

.search input{
  -webkit-appearance: none;
  height: inherit;
  background: transparent;
  border: none;
  padding-left: 40px;
  font-size: 1.2em;
  color: #727272;
  width: 100%;
}

.search input:focus{
  outline: none;
}

/* Place page */
.place-header{
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}

.place-header .container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.place-header + .container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.place-title {
  padding: 32px;
  font-size: 2em;
  text-align: left;
  border-radius: 4px;
  background-color: #fff;
}

/* Option */
.options{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 30px;
  flex-grow: 1;
}

@media screen and (max-width: 390px) {
  .options {
    grid-template-columns: none;
  }
}

.option{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: 160px;
  border: 1px solid rgb(224, 224, 224);
  background-color: #fff;
  cursor: pointer;
}

.option-text {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  text-align: left;
}

.option-image{
  width: 150px;
  background-color: #60b70b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* payment */
.payment{
  padding: 24px;
  border: 1px solid rgb(224, 224, 224);
  margin: 0 0 30px 30px;
  flex-shrink: 0;
  width: 300px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

@media screen and (max-width: 720px) {
  .payment{
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
  }
}

.payment-button{
  -webkit-appearance: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #60b70b;
  height: 48px;
  padding: 0 10px;
  font-size: .8em;
  color: #fff;
  border: none;
  text-decoration: none;
}

/* cart component */
.cart-title{
  font-size: 2em;
  margin: 30px 0;
}

.cart-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.cart-list{
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(224, 224, 224);
  border-bottom: none;
  flex-grow: 1;
}

.cart-item{
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.cart-place{
  font-size: 0.8em;
  color: rgb(123, 123, 123);
  margin-bottom: 5px;
}

.card-order{
  font-size: 1em;
}
